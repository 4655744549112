import Vue from "vue";
import { Menu, Submenu, MenuItem, Input, Button, Pagination, DatePicker, Select, Option, Checkbox, Form, FormItem, Upload, Radio, RadioGroup, Dialog, Table, TableColumn, Progress,Message,MessageBox, Tooltip, Empty } from 'element-ui';

Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(Input)
Vue.use(Select)
Vue.use(Button)
Vue.use(Pagination)
Vue.use(DatePicker)
Vue.use(Select)
Vue.use(Option)
Vue.use(Checkbox)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Upload)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Dialog)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Progress)
Vue.use(Tooltip)
Vue.use(Empty)

Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
