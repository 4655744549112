<template>
  <div>
    <div class="header">
      <div class="content header_box">
        <div class="header_lt">
          <img class="header_login2" src="../assets/img/logonew3.png" alt="" />
          <div class="header_tt">欢迎使用，亚伟智能课堂</div>
        </div>
        <div class="header_rt">
          <div>姓名：{{ username }}</div>
          <div class="header_rt_box">
            <div @click="out">退出登录</div>
            <!-- <div>注册</div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="center content1">
      <div class="center_lt">
        <el-menu
          :default-active="activePath"
          background-color="#75A5FF"
          text-color="#fff"
          class="menu"
          unique-opened
          router
        >
          <!-- <el-menu-item index="/index" @click="saveNavState('/index')">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>后台首页</span>
            </template>
          </el-menu-item> -->
          <el-submenu index="1">
            <template slot="title">
              <i class="iconfont icon-ziyuan-xianxing"></i>
              <span>资源管理</span>
            </template>
            <el-menu-item
              index="/publicResource"
              @click="saveNavState('/publicResource')"
            >
              <template slot="title">
                <span style="margin-left: 10px">公共资源</span>
              </template>
            </el-menu-item>
            <el-menu-item
              index="/publicCollection"
              @click="saveNavState('/publicCollection')"
            >
              <template slot="title">
                <span style="margin-left: 10px">我的资源</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/label" @click="saveNavState('/label')">
              <template slot="title">
                <span style="margin-left: 10px">资源分类</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <i class="iconfont icon-wodezuoye-"></i>
              <span>作业管理</span>
            </template>
            <el-menu-item
              index="/operation"
              @click="saveNavState('/operation')"
            >
              <template slot="title">
                <span style="margin-left: 10px">作业记录</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              <i class="iconfont icon-kaoshi"></i>
              <span>考试管理</span>
            </template>
            <el-menu-item index="/exam" @click="saveNavState('/exam')">
              <template slot="title">
                <span style="margin-left: 10px">考试记录</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">
              <i class="iconfont icon-ketang"></i>
              <span style="margin-left: -6px;">课堂管理</span>
            </template>
            <el-menu-item index="/teaching" @click="saveNavState('/teaching')">
              <template slot="title">
                <span style="margin-left: 10px">我的课堂</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/class" @click="saveNavState('/class')">
              <template slot="title">
                <span style="margin-left: 10px">我的班级</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/free" @click="saveNavState('/free')">
              <template slot="title">
                <span style="margin-left: 10px">自由练习</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/standard" @click="saveNavState('/standard')">
              <template slot="title">
                <span style="margin-left: 10px">标准练习</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="5">
            <template slot="title">
              <i class="iconfont icon-gonggao"></i>
              <span>公告管理</span>
            </template>
            <el-menu-item index="/log" @click="saveNavState('/log')">
              <template slot="title">
                <span style="margin-left: 10px">公告列表</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="6">
            <template slot="title">
              <i class="iconfont icon-user"></i>
              <span>个人中心</span>
            </template>
            <el-menu-item index="/user" @click="saveNavState('/user')">
              <template slot="title">
                <span style="margin-left: 10px">个人信息</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/editEmail" @click="saveNavState('/editEmail')">
              <template slot="title">
                <span style="margin-left: 10px">修改邮箱</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/editPassword" @click="saveNavState('/editPassword')">
              <template slot="title">
                <span style="margin-left: 10px">修改密码</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <!-- <el-submenu index="7">
            <template slot="title">
              <i class="iconfont icon-a-bianzu3"></i>
              <span>情景实训</span>
            </template>
            <el-menu-item index="/text" @click="saveNavState('/text')">
              <template slot="title">
                <span style="margin-left: 10px">情景实训</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/block" @click="saveNavState('/block')">
              <template slot="title">
                <span style="margin-left: 10px">区块链文字信息保密</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/block1" @click="saveNavState('/block1')">
              <template slot="title">
                <span style="margin-left: 10px">新媒体直播信息处理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/block2" @click="saveNavState('/block2')">
              <template slot="title">
                <span style="margin-left: 10px">互联网信息处理创业</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/block3" @click="saveNavState('/block3')">
              <template slot="title">
                <span style="margin-left: 10px">新媒体处理技术以及任务训练</span>
              </template>
            </el-menu-item>
          </el-submenu> -->
        </el-menu>
      </div>
      <div class="center_rt">
        <div class="gg" @click="goannoun"  v-if="notice_content && (activePath != '/log') && (activePath != '/announcement')">
          公告：<span>{{ notice_content }}</span>
        </div>
        <router-view />
      </div>
    </div>
    <div class="foot">
      <div>关于我们</div>
      <div>|</div>
      <div>使用说明</div>
      <div>|</div>
      <div>联系我们</div>
      <div>|</div>
      <div>Copyright</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: '',
      activePath: '/index',
      username: '',
      notice_content: '',
    }
  },
  mounted() {
    this.activePath = window.localStorage.getItem('activePath_student')
    this.username = window.localStorage.getItem('username_student')
    this.studentfirstnotice()
  },
  methods: {
    saveNavState(activePath) {
      console.log(activePath)
      window.localStorage.setItem('activePath_student', activePath)
      this.activePath = activePath
    },
    // 退出登录
    async out() {
      const { data: res } = await this.$http.get('index.php/api/user/logout')
      if (res.code != 1) return
      window.localStorage.removeItem('student_token')
      this.$router.push('/')
    },
    // 学生获取最新一条公告 /index.php/api/notice/notice/studentfirstnotice
    async studentfirstnotice() {
      const { data: res } = await this.$http.get(
        'index.php/api/notice/notice/studentfirstnotice'
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.notice_content = res.data.notice_content.slice(0, 20)
      this.item = res.data
    },
    // 跳转到评论列表
    goannoun() {
      this.saveNavState('/announcement')
      this.$router.push('/announcement?item=' + JSON.stringify(this.item))
    }
  },
}
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 67px;
  background-color: #538eff;
  color: #ffffff;
  .header_box {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header_lt {
      display: flex;
      align-items: center;
      .header_login2 {
        width: 336px;
        height: 63px;
        display: block;
      }
      .header_tt {
        font-size: 16px;
        margin-left: 21px;
      }
    }
    .header_rt {
      display: flex;
      align-items: center;
      .header_rt_tt {
        margin-left: 40px;
        font-size: 16px;
      }
      .header_rt_box {
        display: flex;
        align-items: center;

        div:nth-child(1) {
          width: 80px;
          height: 25px;
          border: 1px solid #ffffff;
          border-radius: 4px;
          margin-left: 22px;
          font-size: 14px;
          text-align: center;
          line-height: 25px;
          cursor: pointer;
        }
        div:nth-child(2) {
          width: 54px;
          height: 25px;
          background: #ffffff;
          border-radius: 4px;
          margin-left: 14px;
          font-size: 14px;
          text-align: center;
          line-height: 25px;
          color: #248df8;
        }
      }
    }
  }
}
.center {
  display: flex;
  padding: 18px 0;
  .center_lt {
    width: 250px;
    min-width: 250px;
    min-height: calc(100vh - 193px);
    background-color: #75a5ff;
    border-radius: 4px;
    padding: 15px 0;
    margin-right: 20px;
    .menu {
      width: 251px;
    }
  }
  .center_rt {
    flex: 1;
    .gg {
      border-bottom: 1px solid #e7eaef;
      font-size: 16px;
      padding: 10px;
      margin-bottom: 20px;
      cursor: pointer;
      span {
        color: #538eff;
      }
    }
  }
}
.foot {
  width: 100%;
  height: 90px;
  background-color: #538eff;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c7e3fe;
  div {
    margin: 0 26px;
  }
}
</style>
