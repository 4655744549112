<template>
  <div>
    <div class="serve">
      <div class="serve_box">
        <span>标签名称：</span>
        <el-input
          size="small"
          class="ipt"
          placeholder="请输入内容"
          v-model="name"
          clearable
        >
        </el-input>
        <el-button size="small" type="primary" @click="sousuo">搜索</el-button>
        <el-button size="small" type="primary" @click="add">添加</el-button>
        <el-button size="small" type="primary" plain @click="del"
          >批量删除</el-button
        >
      </div>
    </div>
    <el-table
      :data="indexList"
      stripe
      style="width: 100%"
      @selection-change="selectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column type="index" label="序号"> </el-table-column>
      <el-table-column prop="tag_name" label="标签名称"> </el-table-column>
      <el-table-column prop="remarks" label="备注"> </el-table-column>
      <el-table-column prop="address" label="操作" width="90px">
        <template v-slot="scoped">
          <el-button
            size="mini"
            icon="el-icon-edit"
            type="primary"
            @click="edit(scoped.row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagina">
      <el-pagination
        :page-size="limit"
        :current-page="page"
        @current-change="handleCurrentChange"
        background
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 添加资源标签弹出框 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="700px"
      :before-close="handleClose"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
        size="small"
      >
        <el-form-item label="标签名称: " prop="name">
          <el-input class="ipt" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="备注: ">
          <el-input
            class="ipt"
            type="textarea"
            :rows="2"
            v-model="form.remarks"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button v-if="title == '添加'" type="primary" @click="add_btn"
            >立即创建</el-button
          >
          <el-button v-else type="primary" @click="edit_btn">确定</el-button>
          <el-button @click="handleClose">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: '',
      limit: 10,
      page: 1,
      indexList: [],
      total: 0,
      title: '',
      dialogVisible: false,
      form: {
        name: '',
        remarks: '',
      },
      rules: {
        name: [{ required: true, message: '请填写标签名称', trigger: 'blur' }],
      },
      speakindexListChecked: [],
    }
  },
  mounted() {
    this.index()
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val
      this.index()
    },
    handleClose() {
      this.dialogVisible = false
    },
    // 标签列表
    async index() {
      const { data: res } = await this.$http.get(
        'index.php/api/resources/resources/resourcestagindex',
        {
          params: {
            name: this.name,
            limit: this.limit,
            page: this.page,
          },
        }
      )

      this.indexList = res.data.list
      this.total = res.data.total
    },
    // 搜索标签
    sousuo() {
      this.page = 1
      this.index()
    },
    // 添加标签弹出框
    add() {
      this.dialogVisible = true
      this.title = '添加'
    },
    // 添加标签
    add_btn() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整数据！')
        const { data: res } = await this.$http.post(
          'index.php/api/resources/resources/addresourcetag',
          this.form
        )
        if (res.code != 1) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.dialogVisible = false
        this.$refs.form.resetFields()
        this.form = {
          speak_name: '',
          remarks: '',
        }
        this.title = ''
        this.index()
      })
    },
    // 编辑标签弹出框
    edit(row) {
      this.dialogVisible = true
      this.title = '编辑'
      this.form = {
        name: row.tag_name,
        remarks: row.remarks,
        tag_no: row.tag_no,
      }
    },
    // 编辑标签
    edit_btn() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整数据！')
        const { data: res } = await this.$http.post(
          'index.php/api/resources/resources/editresourcetag',
          this.form
        )
        if (res.code != 1) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.dialogVisible = false
        this.$refs.form.resetFields()
        this.form = {
          speak_name: '',
          remarks: '',
        }
        this.title = ''
        this.index()
      })
    },
    selectionChange(selection) {
      console.log(4, selection)
      this.speakindexListChecked = selection
    },
    // 删除标签
    // 批量删除
    async del() {
      let tag_no = []
      if (this.speakindexListChecked.length <= 0)
        return this.$message.error('请勾选要删除的标签！')
      this.speakindexListChecked.forEach((e) => {
        tag_no.push(e.tag_no)
      })
      const { data: res } = await this.$http.post(
        'index.php/api/resources/resources/delresourcetag',
        {
          tag_no: tag_no.join(),
        }
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.index()
    },
  },
}
</script>

<style lang='less' scoped>
.serve {
  margin-bottom: 30px;
  display: flex;
  .serve_box {
    display: flex;
    align-items: center;
    margin-right: 30px;
    span {
      color: #535454;
      font-size: 14px;
    }
    .ipt {
      width: 220px;
    }
  }
}
.pagina {
  text-align: center;
  padding: 20px;
}
.ipt {
  width: 400px;
}
</style>