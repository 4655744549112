<template>
  <div>
    <div class="header">
      <div class="header_box content2">
        <div class="title">亚伟中文速录云教学实训系统</div>
        <div class="header_rt_box">
          <div @click="goLogin">登录</div>
          <div>注册</div>
        </div>
      </div>
    </div>
    <div class="detail">
      <div class="box">
        <div class="lt_img">
          <img
            :src="item.resources_url_host + item.resources_cover_pic"
            alt=""
          />
        </div>
        <div class="rt_box">
          <div class="tt">{{ item.resources_name }}</div>
          <div class="tt1">课程简介</div>
          <div class="text">
            {{ item.resources_content }}
          </div>
          <div class="btn">
            <div
              class="btn_lt"
              @click="down(item.resources_url_host + item.resources_url)"
            >
              <img class="img1" src="../assets/img/xiazai2.png" alt="" />
              <div>下载资源</div>
            </div>
            <div class="btn_lt" @click="shoucang">
              <img class="img2" src="../assets/img/xingxing.png" alt="" />
              <div>收藏</div>
            </div>
          </div>
        </div>
      </div>
      <div class="box2">
        <div class="lt">
          <!-- 音频 -->
          <div v-if="resources_type == 3">
              <video width="520" height="100" controls>
                  <source :src="resources_url">
                  您的浏览器不支持 video 标签。
              </video>
          </div>
          <!-- 视频 -->
          <div v-if="resources_type == 4">
            <video width="520" height="340" controls>
                  <source :src="resources_url">
                  您的浏览器不支持 video 标签。
              </video>
            
          </div>
          <!-- 文本 -->
          <div v-if="resources_type == 5 && resources_url.slice(-3) == 'pdf'">
            <iframe
              width="640"
              height="400"
              :src="resources_url"
              frameborder="0"
            ></iframe>
          </div>
          <div v-if="resources_type == 5 && resources_url.slice(-3) != 'pdf'">
            <iframe
              width="640"
              height="400"
              :src="
                'https://view.officeapps.live.com/op/view.aspx?src=' +
                resources_url
              "
              frameborder="0"
            ></iframe>
          </div>
          <div v-if="resources_type == 6">
            <div>请前往考试系统考试</div>
          </div>
        </div>
        <div class="rt">
          <div class="box3" v-for="it in indexList" :key="it.id">
            <div class="img_box">
              <img
                :src="it.resources_url_host + it.resources_cover_pic"
                alt=""
              />
            </div>
            <div class="tt">{{ it.resources_name }}</div>
            <div class="text">
              {{ it.resources_content }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item: {},
      indexList: [],
      form: {
        resources_no: '',
        resources_name: '',
        tag_nos: [],
        remarks: '',
      },
      dialogVisible: false,
      resources_type: 1,
      resources_url: ''
    }
  },
  mounted() {
    this.item = JSON.parse(this.$route.query.item) || ''
    console.log(this.item)
    if (this.item) {
      this.resources_type = this.item.resources_type
      this.resources_url = this.item.resources_url_host + this.item.resources_url
      this.pubresourcebytype()
    }
  },
  methods: {
    goLogin() {
      this.$router.push('/login')
    },
    // 公共资源相关资源列表
    async pubresourcebytype() {
      const { data: res } = await this.$http.get(
        'index.php/api/resources/resources/pubresourcebytype',
        {
          params: {
            resources_type: this.resources_type,
          },
        }
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.indexList = res.data.list
    },
     
    // 下载资源
    down() {
      this.$message.error('请登录后操作')
    },
    // 收藏弹出框
    shoucang() {
      this.$message.error('请登录后操作')
    }
    
  },
}
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 110px;
  background: #538eff;

  .header_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @font-face {
      font-family: myFirstFont;
      src: url('../assets/FZXKJW.TTF');
    }
    .title {
      font-size: 48px;
      font-family: FZXingKai-S04S;
      color: #ffffff;
      font-family: myFirstFont;
    }
    .header_rt_box {
      display: flex;
      align-items: center;
      color: #ffffff;
      div:nth-child(1) {
        width: 73px;
        height: 37px;
        border: 1px solid #ffffff;
        border-radius: 15px;
        font-size: 19px;
        text-align: center;
        line-height: 37px;
        cursor: pointer;
      }
      div:nth-child(2) {
        width: 73px;
        height: 37px;
        background: #ffffff;
        border-radius: 15px;
        margin-left: 30px;
        font-size: 19px;
        text-align: center;
        line-height: 37px;
        color: #538eff;
        cursor: pointer;
      }
    }
  }
}
.detail {
  width: 100%;
  background-color: #f1f4f8;
  min-height: calc(100vh - 110px);
  overflow: hidden;
  .box {
    width: 1310px;
    height: 250px;
    background-color: #fff;
    margin: 28px auto;
    display: flex;
    align-items: center;
    padding: 0 70px 0 35px;
    .lt_img {
      width: 324px;
      min-width: 324px;
      height: 182px;
      margin-right: 26px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .rt_box {
      flex: 1;
      .tt {
        font-size: 24px;
        color: #444444;
        margin-bottom: 16px;
      }
      .tt1 {
        width: 100%;
        font-size: 14px;
        color: #444444;
        padding-left: 5px;
        border-left: 2px solid #538eff;
      }
      .text {
        font-size: 14px;
        color: #444444;
        margin: 5px 0 27px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .btn {
        display: flex;
        .btn_lt {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 181px;
          height: 43px;
          background: #538eff;
          border-radius: 3px;
          font-size: 20px;
          color: #ffffff;
          margin-right: 34px;
          cursor: pointer;
          .img1 {
            width: 19px;
            height: 20px;
            display: block;
            margin-right: 10px;
          }
          .img2 {
            width: 25px;
            height: 24px;
            display: block;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .box2 {
    display: flex;
    justify-content: space-between;
    width: 1310px;
    margin: 0 auto 100px;
    .lt {
      width: 880px;
      // height: 400px;
      background-color: #fff;
      margin-bottom: 25px;
    }
    .rt {
      .box3 {
        width: 400px;
        padding: 12px 12px 30px;
        background-color: #fff;
        margin-bottom: 25px;
        .img_box {
          width: 376px;
          height: 213px;
          // margin: 11px auto;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .tt {
          font-size: 24px;
          color: #333333;
          margin: 37px 0 27px 0;
        }
        .text {
          font-size: 16px;
          color: #666666;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          // margin-bottom: 5px;
        }
      }
    }
  }
}
.ipt {
  width: 400px;
}
</style>