<template>
  <div>
    <div class="serve">
      <el-button size="small" type="primary" @click="add">加入班级</el-button>
    </div>
    <el-table :data="indexList" stripe style="width: 100%">
      <el-table-column type="index" label="序号"> </el-table-column>
      <el-table-column prop="class_name" label="班级名称"> </el-table-column>
      <el-table-column prop="teacher_name" label="所属教师"> </el-table-column>
    </el-table>
    <div class="pagina">
      <el-pagination
        @current-change="handleCurrentChange"
        background
        :page-size="limit"
        :current-page="page"
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 添加班级弹出框 -->
    <el-dialog
      title="添加"
      :visible.sync="dialogVisible"
      width="700px"
      :before-close="handleClose"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
        size="small"
      >
        <el-form-item label="教师名字: " prop="teacher_no">
          <el-select @change="teacherChange" class="ipt" v-model="form.teacher_no" filterable  placeholder="关键字搜索">
            <el-option
              v-for="item in allteacherList"
              :key="item.user_no"
              :label="item.nickname"
              :value="item.user_no"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="班级名称: ">
          <el-select v-model="form.class_id"  class="ipt" filterable  placeholder="关键字搜索">
            <el-option
              v-for="item in teacherclassList"
              :key="item.id"
              :label="item.class_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="add_btn">确定</el-button>
          <el-button @click="handleClose">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      limit: 10,
      page: 1,
      indexList: [],
      total: 0,
      dialogVisible: false,
      form: {
        teacher_no: '',
        class_id: '',
      },
      rules: {
        teacher_no: [{ required: true, message: '请选择教师', trigger: 'change' }],
        class_id: [{ required: true, message: '请选择班级', trigger: 'change' }],
      },
      allteacherList: [],
      teacherclassList: []
    }
  },
  mounted() {
    this.index()
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val
      this.index()
    },
    handleClose() {
      this.dialogVisible = false
      this.$refs.form.resetFields()
    },
    // 我加入的班级 /index.php/api/student/course/myclass
    async index() {
      const { data: res } = await this.$http.get(
        'index.php/api/student/course/myclass',
        {
          params: {
            limit: this.limit,
            page: this.page,
          },
        }
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.indexList = res.data.list
      this.total = res.data.total
    },
    // 搜索
    sousuo() {
      this.page = 1
      this.index()
    },
    // 添加弹出框
    add() {
      this.dialogVisible = true
     
      this.allteacher()

    },
    // 教师事件
    teacherChange(){
      if(this.form.teacher_no) {
        this.teacherclassList = []
        this.form.class_id = ''
        this.teacherclass()
      }
    },
    // 获取所有老师 /index.php/api/student/course/allteacher
    async allteacher() {
      const { data: res } = await this.$http.get(
        'index.php/api/student/course/allteacher',
        {
          params: {
            limit: 999,
            page: 1,
          },
        }
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.allteacherList = res.data.list
    },
    // 获取老师班级 /index.php/api/student/course/teacherclass
    async teacherclass() {
      const { data: res } = await this.$http.get(
        'index.php/api/student/course/teacherclass',
        {
          params: {
            limit: 999,
            page: 1,
            teacher_no: this.form.teacher_no
          },
        }
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.teacherclassList = res.data.list
    },
    // 加入老师班级 /index.php/api/student/course/enterclass
    add_btn() {
      this.$refs.form.validate(async valid => {
        if(!valid) return this.$message.error('请填写完整数据！')
        const { data: res } = await this.$http.get('index.php/api/student/course/enterclass',
          {
            params: this.form
          }
        )
        if(res.code != 1) return this.$message.error(res.msg)
        this.dialogVisible = false
        this.sousuo()
        this.$refs.form.resetFields()
        this.$message.success(res.msg)
      })
    }
  },
}
</script>

<style lang='less' scoped>
.serve {
  margin-bottom: 30px;
  display: flex;
  .serve_box {
    display: flex;
    align-items: center;
    margin-right: 30px;
    span {
      color: #535454;
      font-size: 14px;
    }
    .ipt {
      width: 220px;
    }
  }
}
.answer_type {
  color: #ff0000;
}
.answer_type1 {
  color: #27b64f;
}
.pagina {
  text-align: center;
  padding: 20px;
}
.ipt {
  width: 400px;
}
</style>