<template>
  <div class="box">
    <div class="box1" v-for="(item, i) in indexList" :key="i">
      <div class="text">
        <span v-if="i == 0">一，</span>
        <span v-if="i == 1">二，</span>
        <span v-if="i == 2">三，</span>
        <span v-if="i == 3">四，</span>
        {{ item.title
        }}<span class="span1">（{{ item.listQues.length }}道）</span>
      </div>
      <div
        v-if="
          item.questions_class == 'radio' || item.questions_class == 'decide'
        "
      >
        <div class="list" v-for="(it, e) in item.listQues" :key="e">
          <div class="title">{{ i + 1 }}.{{ it.title }}</div>
          <div class="radio_box" v-for="(it1, e1) in it.listAnswer" :key="e1">
            <!-- 单选题 -->
            <el-radio
              v-model="it.member_question_answer"
              :label="it1.question_answer_id"
              >{{ it1.title }}
            </el-radio>
          </div>
          <div class="list_btn">
            <div class="btn_rt1">
              <div>正确答案：</div>
              <div v-for="(it1, e1) in it.listAnswer" :key="e1">
                <span v-if="it.system_right_answer == it1.question_answer_id">{{
                  it1.title
                }}</span>
              </div>
            </div>
            <div>
              <div class="btn_lt">
                <div>考生答案：</div>
                <div v-for="(it1, e1) in it.listAnswer" :key="e1">
                  <span
                    :class="
                      it.system_right_answer != it.member_question_answer
                        ? 'span'
                        : ''
                    "
                    v-if="it.member_question_answer == it1.question_answer_id"
                    >{{ it1.title }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="item.questions_class == 'checkbox'">
        <div class="list" v-for="(it, e) in item.listQues" :key="e">
          <div class="title">{{ i + 1 }}.{{ it.title }}</div>
          <div class="radio_box" v-for="(it1, e1) in it.listAnswer" :key="e1">
            <el-checkbox :value="it.system_right_answer.includes(it1.question_answer_id)">{{ it1.title }}</el-checkbox>
          </div>
          <!-- <el-checkbox-group :value="it.system_right_answer.split(',')">
            <el-checkbox :label="it1.question_answer_id" v-for="(it1, e1) in it.listAnswer" :key="e1">{{it1.title}}</el-checkbox>
          </el-checkbox-group> -->
          <div class="list_btn">
            <div class="btn_rt1">
              <div>正确答案：</div>
              <div v-for="(it1, e1) in it.listAnswer" :key="e1">
                <span v-if="it.system_right_answer.includes(it1.question_answer_id)">
                  <span>{{it1.title}}，</span>
                </span>
                
              </div>
            </div>
            <div>
              <div class="btn_lt">
                <div>考生答案：</div>
                <div v-for="(it1, e1) in it.listAnswer" :key="e1">
                  <span
                    :class="
                      it.system_right_answer != it.member_question_answer
                        ? 'span'
                        : ''
                    "
                    v-if="it.member_question_answer.includes(it1.question_answer_id)"
                    >
                      <span>{{it1.title}}，</span>
                    </span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="
          item.questions_class == 'listen' || item.questions_class == 'answer'
        "
      >
        <div class="list" v-for="(it, e) in item.listQues" :key="e">
          <div class="title">{{ i + 1 }}.{{ it.title }}</div>
          <div class="listen_box" v-for="(it1, e1) in it.listAnswer" :key="e1">
            <!-- 音频 -->
            <video
              width="700"
              height="65"
              controls
              v-if="item.questions_class == 'listen'"
            >
              <source :src="it1.audio" />
              您的浏览器不支持 video 标签。
            </video>
          </div>
          <div>
            <div class="btn_zq">正确答案：</div>
            <div class="tt1">
              {{ it.system_right_answer }}
            </div>
          </div>
          <div>
            <div class="btn_ks">考生答案：</div>
            <div
              :class="
                it.system_right_answer != it.member_question_answer
                  ? 'tt'
                  : 'tt1'
              "
            >
              {{ it.member_question_answer }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      indexList: [],
    }
  },
  mounted() {
    this.homework_no = this.$route.query.homework_no
    this.exam_record_id = this.$route.query.exam_record_id
    this.ks_detail()
  },
  methods: {
    // 学生-获取考试结果详情 /index.php/api/homework/homework/studentgetexaminfo
    async ks_detail() {
      const { data: res } = await this.$http.post(
        'index.php/api/homework/homework/studentgetexaminfo',
        {
          homework_no: this.homework_no,
          exam_record_id: this.exam_record_id,
        }
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.indexList = res.data.list
    },
  },
}
</script>

<style lang="less" scoped>
.tt {
  font-size: 16px;
  color: #535454;
  margin-bottom: 20px;
  span {
    color: #98989e;
  }
}
.box {
  width: 100%;
  height: calc(100vh - 197px);
  background: #ffffff;
  // border: 2px solid #f1f4f8;
  // box-shadow: 11px 0px 0px 0px rgba(162, 162, 162, 0.15);
  overflow-y: scroll;
  padding: 20px;
  .text {
    font-size: 16px;
    color: #000000;
    .span1 {
      color: #999999;
    }
  }
  .box1 {
    margin-bottom: 30px;
  }

  .list {
    overflow: hidden;
    .title {
      font-size: 16px;
      color: #000000;
      margin: 25px 0 20px;
    }
    .radio_box {
      width: 100%;
      height: 58px;
      background: #f1f4f8;
      margin-bottom: 18px;
      font-size: 16px;
      line-height: 58px;
      padding: 0 20px;
    }
    .btn_zq {
      padding-left: 8px;
      border-left: 6px solid #03d071;
      margin: 20px 0 10px 10px;
      letter-spacing: 1px;
    }
    .btn_ks {
      padding-left: 8px;
      border-left: 6px solid #0699f8;
      margin: 20px 0 10px 10px;
    }
    .tt1 {
      color: #333;
      letter-spacing: 1px;
      font-size: 14px;
      font-weight: 600;
    }
    .tt {
      color: #f56c6c;
      letter-spacing: 1px;
      font-size: 14px;
      font-weight: 600;
    }
    .list_btn {
      display: flex;
      font-size: 16px;
      color: #000000;
      .btn_lt {
        display: flex;
        padding-left: 8px;
        border-left: 6px solid #0699f8;
        margin: 0 80px 0 20px;
        .span {
          color: #f56c6c;
        }
      }
      .btn_rt {
        display: flex;
        padding-left: 8px;
        border-left: 6px solid #f56c6c;
      }
      .btn_rt1 {
        display: flex;
        padding-left: 8px;
        border-left: 6px solid #03d071;
      }
    }
  }
}
.btn {
  margin: 30px auto;
  text-align: center;
}
</style>