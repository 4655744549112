<template>
  <div>
    <div class="header">
      <div class="content header_box">
        <div class="header_lt">
          <img class="header_login2" src="../assets/img/logonew3.png" alt="" />
          <div class="header_tt">欢迎使用，亚伟智能课堂</div>
        </div>
        <div class="header_rt">
          <div class="header_rt_tt" @click="goIndex">首页</div>
          <!-- <div class="header_rt_tt">产品</div>
          <div class="header_rt_tt">操作指南</div>
          <div class="header_rt_tt">联系我们</div> -->
          <div class="header_rt_box">
            <div @click="goLogin">登录</div>
            <!-- <div>注册</div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="center">
      <div class="center_lt">
        <img class="center_lt_img" src="../assets/img/logobg1.png" alt="" />
        <div class="center_lt_tt">智慧课堂管理平台</div>
      </div>
      <div class="center_rt">
        <img class="center_rt_img" src="../assets/img/login1.png" alt="" />
        <div class="center_rt_tt">学生系统</div>
        <div class="center_rt_ipt">
          <input
            type="text"
            v-model.trim="username"
            placeholder="用户名"
          />
        </div>
        <div class="center_rt_ipt">
          <input
            type="text"
            v-model.trim="nickname"
            placeholder="姓名"
          />
        </div>
        <div class="center_rt_ipt">
          <input
            type="text"
            v-model.trim="password"
            placeholder="密码"
          />
        </div>
        <div class="center_rt_ipt">
          <input
            type="text"
            v-model.trim="email"
            placeholder="邮箱"
          />
        </div>
        <div class="center_rt_ipt">
          <input
            type="text"
            v-model.trim="mobile"
            placeholder="手机号"
          />
        </div>
        <div class="center_rt_ipt">
          <input
            type="text"
            v-model.trim="card_num"
            placeholder="身份证号"
          />
        </div>
        <div class="center_rt_ipt">
          <input
            type="text"
            v-model.trim="study_num"
            placeholder="学号"
            @keyup.enter="register"
          />
        </div>
        <div class="login" @click="register">注册</div>
      </div>
    </div>
    <div class="foot">
      <div>关于我们</div>
      <div>|</div>
      <div>使用说明</div>
      <div>|</div>
      <div>联系我们</div>
      <div>|</div>
      <div>Copyright</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      nickname: '',
      password: '',
      email: '',
      mobile: '',
      card_num: '',
      study_num: ''
    }
  },
  mounted() {
  },
  methods: {
    goLogin() {
      this.$router.push('/login')
    },
    goIndex() {
      this.$router.push('/')
    },
    // 注册
    async register() {
      if (this.username.length <= 0) {
        return this.$message.error('请输入用户名')
      }
      if (this.nickname.length <= 0) {
        console.log(this.captcha.length)
        return this.$message.error('请输入姓名')
      }
      if (this.password.length <= 0) {
        console.log(this.captcha.length)
        return this.$message.error('请输入密码')
      }
      if (this.email.length <= 0) {
        console.log(this.captcha.length)
        return this.$message.error('请输入邮箱')
      }
      if (this.mobile.length <= 0) {
        console.log(this.captcha.length)
        return this.$message.error('请输入手机号')
      }
      if (this.card_num.length <= 0) {
        console.log(this.captcha.length)
        return this.$message.error('请输入身份证号')
      }
      if (this.study_num.length <= 0) {
        console.log(this.captcha.length)
        return this.$message.error('请输入学号')
      }
      
      const { data: res } = await this.$http.post('index.php/api/user/register', {
        username: this.username,
        nickname: this.nickname,
        password: this.password,
        email: this.email,
        mobile: this.mobile,
        card_num: this.card_num,
        study_num: this.study_num,

      })
      if (res.code != 1) {
        return this.$message.error(res.msg)
      }
      this.$message.success(res.msg)
      this.$router.push('/login')
    },
  },
}
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 67px;
  background-color: #538eff;
  color: #ffffff;
  .header_box {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header_lt {
      display: flex;
      align-items: center;
      .header_login2 {
        width: 336px;
        height: 63px;
        display: block;
      }
      .header_tt {
        font-size: 16px;
        margin-left: 21px;
      }
    }
    .header_rt {
      display: flex;
      align-items: center;
      .header_rt_tt {
        margin-left: 40px;
        font-size: 16px;
        cursor: pointer;
      }
      .header_rt_box {
        display: flex;
        align-items: center;

        div:nth-child(1) {
          width: 52px;
          height: 25px;
          border: 1px solid #ffffff;
          border-radius: 4px;
          margin-left: 22px;
          font-size: 14px;
          text-align: center;
          line-height: 25px;
          cursor: pointer;
        }
        div:nth-child(2) {
          width: 54px;
          height: 25px;
          background: #ffffff;
          border-radius: 4px;
          margin-left: 14px;
          font-size: 14px;
          text-align: center;
          line-height: 25px;
          color: #248df8;
          cursor: pointer;
        }
      }
    }
  }
}
.center {
  width: 100%;
  height: calc(100vh - 157px);
  // min-height: 932px;
  background: url('../assets/img/loginbg.png') no-repeat center center;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  .center_lt {
    width: 555px;
    height: 718px;
    background: #538eff;
    text-align: center;
    .center_lt_img {
      width: 422px;
      height: 519px;
      display: block;
      margin: 82px auto 10px;
    }
    .center_lt_tt {
      font-size: 32px;
      color: #ffffff;
      font-family: HYk1gj;
      letter-spacing: 2px;
    }
  }
  .center_rt {
    width: 484px;
    height: 718px;
    background-color: #fff;
    .center_rt_img {
      width: 175px;
      height: 58px;
      display: block;
      margin: 86px auto 21px;
    }
    .center_rt_tt {
      width: 143px;
      height: 32px;
      background: #538eff;
      border-radius: 15px;
      font-size: 21px;
      color: #ffffff;
      text-align: center;
      line-height: 32px;
      margin: 0 auto 20px;
    }
    .center_rt_ipt {
      border-bottom: 1px solid #dee0e2;
      padding: 2px 3px;
      width: 353px;
      margin: 13px auto 0;
      font-size: 14px;
      input {
        height: 35px;
        border: 0px;
        background-color: none;
        width: 100%;
        outline: none;
        font-size: 16px;
      }
      input:focus {
        outline: none;
      }
      input::-webkit-input-placeholder {
        font-size: 16px;
        color: #999999;
      }
      .yzm {
        width: 130px;
        height: 40px;
        background: #a0bbff;
        // border-radius: 18px;
        // font-size: 26px;
        // color: #ffffff;
        // text-align: center;
        // line-height: 35px;
        // letter-spacing: 4px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .center_rt_ipt1 {
      display: flex;
      justify-content: space-between;
    }
    .password {
      width: 353px;
      margin: 25px auto;
      color: #8e8e8e;
      font-size: 14px;
    }
    .login {
      width: 347px;
      height: 43px;
      background: #4277ff;
      border: 1px solid #4277ff;
      box-shadow: 0px 0px 18px 0px rgba(66, 119, 255, 0.52);
      border-radius: 4px;
      margin: 40px auto 20px;
      font-size: 21px;
      color: #ffffff;
      text-align: center;
      line-height: 43px;
      cursor: pointer;
    }
    .register {
      font-size: 21px;
      color: #4277ff;
      text-align: center;
      cursor: pointer;
    }
  }
}
.foot {
  width: 100%;
  height: 90px;
  background-color: #538eff;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c7e3fe;
  div {
    margin: 0 26px;
  }
}
</style>
