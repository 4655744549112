<template>
  <div>
    <div
      class="list"
      v-for="item in indexList"
      :key="item.id"
      @click="goDetail(item)"
    >
      <div class="box1">
        <div class="box1_img">
          <!-- <img src="../assets/img/xingzhuang.png" alt=""> -->
          <div v-if="item.exercise_description == 1">看打</div>
          <div v-if="item.exercise_description == 2">听打</div>
          <div v-if="item.exercise_description == 3">理论</div>
          <div v-if="item.exercise_description == 4">键位</div>
          <div v-if="item.exercise_description == 5">其他</div>
        </div>
        <!-- <el-checkbox class="check" v-model="checked"></el-checkbox> -->
      </div>
      <div class="box2">
        <div class="box2_tp">
          <span class="span" v-if="item.node_id && item.question_library_id"
            >题库练习</span
          >
          <span class="span" v-else>文本练习</span>
          <span>{{ item.exercise_name }}</span>
        </div>
        <div class="box2_ct">{{ item.add_date.split(' ')[1] }}</div>
        <div class="box2_bm">日期：{{ item.add_date.split(' ')[0] }}</div>
      </div>
      <div class="box3">练习要求</div>
      <div class="box4">
        <div class="box4_tp">
          <span>{{ item.exercise_config.practice_nums }}</span
          >次
        </div>
        <div class="box4_bm">
          <img src="../assets/img/lianxi.png" alt="" />
          <div>练习次数</div>
        </div>
      </div>
      <div class="box5">
        <div class="box5_tp">
          <span>{{ item.exercise_config.practice_times }}</span
          >时
        </div>
        <div class="box5_bm">
          <img src="../assets/img/shipin.png" alt="" />
          <div>媒体时长</div>
        </div>
      </div>
      <div class="box6">
        <div class="box6_tp">
          <span>{{ item.exercise_config.standard_accuracy }}</span
          >%
        </div>
        <div class="box6_bm">
          <img src="../assets/img/zhanbi.png" alt="" />
          <div>达标准确率</div>
        </div>
      </div>
      <div class="box7">达标后终止</div>
    </div>
    <div class="pagina">
      <el-pagination
        :page-size="limit"
        :current-page="page"
        @current-change="handleCurrentChange"
        background
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checked: false,
      total: 0,
      page: 1,
      limit: 4,
      indexList: [],
    }
  },
  mounted() {
    this.index()
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val
      this.index()
    },
    // 学生-查看练习列表 /index.php/api/exercise/exercise/studentexerciselist
    async index() {
      const { data: res } = await this.$http.get(
        'index.php/api/exercise/exercise/studentexerciselist',
        {
          params: {
            limit: this.limit,
            page: this.page,
            exercise_type: 1,
          },
        }
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.indexList = res.data.list
      this.total = res.data.total
    },
    // 字符串转base64
    encode(str) {
      // 对字符串进行编码
      var encode = encodeURI(str)
      // 对编码的字符串转化base64
      var base64 = btoa(encode)
      return base64
    },
    // base64转字符串
    decode(base64) {
      // 对base64转编码
      var decode = atob(base64)
      // 编码转字符串
      var str = decodeURI(decode)
      return str
    },
    // 开始练习 自由练习
    goDetail(item) {
      const date = new Date().getTime()
      const exercise_description = item.exercise_description
      const exercise_config = item.exercise_config
      let jurl = ''
      if (item.exercise_description == 4) {
        jurl = item.exercise_content
      }
      exercise_config.exercise_count_down = item.exercise_count_down
      exercise_config.exercise_description = item.exercise_description + ''
      exercise_config.type = '1'
      const config = this.encode(JSON.stringify(exercise_config))
      this.cc = false
      if (exercise_description == 1 || exercise_description == 2) {
        this.cc = true
      }
      if (item.node_id) {
        this.$router.push(
          '/standardDetail?exercise_no=' +
            item.exercise_no +
            '&date=' +
            date +
            '&jurl=' +
            jurl +
            '&type=' +
            (this.cc ? 'ocx' : '') +
            '&config=' +
            config
        )
      } else {
        this.$router.push(
          '/standardDetailtext?exercise_no=' +
            item.exercise_no +
            '&date=' +
            date +
            '&jurl=' +
            jurl +
            '&type=' +
            (this.cc ? 'ocx' : '') +
            '&config=' +
            config
        )
      }
    },
  },
}
</script>

<style lang="less" scoped>
.list {
  width: 100%;
  height: 145px;
  border: 1px solid #b5b5b5;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 20px;
  color: #6a6a6a;
  cursor: pointer;
  .box1 {
    .box1_img {
      width: 92px;
      height: 91px;
      background: url('../assets/img/xingzhuang.png') no-repeat center left;
      background-size: 100% 100%;
      margin: -20px 0 0 -20px;
      overflow: hidden;
      div {
        transform: rotate(-45deg);
        margin-top: 6px;
        margin-left: 12px;
        color: #fff;
        font-size: 16px;
      }
    }
    .check {
      margin: 20px 0 0 0;
    }
  }
  .box2 {
    text-align: center;
    .box2_tp {
      width: 220px;
      .span {
        margin-right: 40px;
      }
    }
    .box2_ct {
      font-size: 30px;
      color: #538eff;
      margin: 15px 0 11px 0;
    }
    .box2_bm {
      font-size: 16px;
    }
  }
  .box3 {
    width: 29px;
    height: 105px;
    border: 1px solid #538eff;
    color: #538eff;
    text-align: center;
    padding-top: 8px;
  }
  .box4 {
    text-align: center;
    .box4_tp {
      color: #538eff;
      font-size: 16px;
      margin-bottom: 30px;
      span {
        font-size: 30px;
      }
    }
    .box4_bm {
      display: flex;
      align-items: center;
      img {
        width: 12px;
        height: 13px;
        display: block;
        margin-right: 7px;
      }
    }
  }
  .box5 {
    text-align: center;
    .box5_tp {
      color: #538eff;
      font-size: 16px;
      margin-bottom: 30px;
      span {
        font-size: 30px;
      }
    }
    .box5_bm {
      display: flex;
      align-items: center;
      img {
        width: 19px;
        height: 13px;
        display: block;
        margin-right: 7px;
      }
    }
  }
  .box6 {
    text-align: center;
    .box6_tp {
      color: #ff9b37;
      font-size: 16px;
      margin-bottom: 30px;
      span {
        font-size: 30px;
      }
    }
    .box6_bm {
      display: flex;
      align-items: center;
      img {
        width: 15px;
        height: 15px;
        display: block;
        margin-right: 7px;
      }
    }
  }
  .box7 {
    color: #538eff;
  }
}
.pagina {
  text-align: center;
  padding: 20px;
}
</style>