<template>
  <div>
    <div class="header">
      <div class="header_box content2">
        <div class="title">亚伟中文速录云教学实训系统</div>
        <div class="header_rt_box">
          <div @click="goLogin">登录</div>
          <div @click="goRegister">注册</div>
        </div>
      </div>
    </div>
    <div class="nav">
      <div class="nav_box content2">
        <div @click="goIndex">首页</div>
        <div class="active">资源中心</div>
      </div>
    </div>
    <div class="zhong">
      <div class="type">
        <div class="type_box">
          <div class="title">媒体类型:</div>
          <div class="box">
            <span
              @click="document_typeChange('0')"
              :class="document_type == '0' ? 'active' : ''"
              >全部</span
            >
            <span
              @click="document_typeChange(name)"
              :class="document_type == name ? 'active' : ''"
              v-for="(value, name) in document_typeObj"
              :key="name"
              >{{ value }}</span
            >
          </div>
        </div>
      </div>
      <div class="list">
        <div class="top">
          <div class="top_lt">
            <div @click="sortChange(1)" :class="sort == 1 ? 'active' : ''">
              默认排序
            </div>
            <div @click="sortChange(2)" :class="sort == 2 ? 'active' : ''">
              发布时间
            </div>
            <div @click="sortChange(3)" :class="sort == 3 ? 'active' : ''">
              素材大小
            </div>
          </div>
          <div class="top_rt">
            <div class="total">
              共计<span>{{ total1 }}</span
              >个资源
            </div>
            <el-input
              class="ipt"
              size="small"
              placeholder="请输入资源名称"
              v-model="resources_name"
              clearable
            >
            </el-input>
            <el-button size="small" type="primary">搜索</el-button>
          </div>
        </div>
        <div class="bm">
          <div
            class="box"
            @click="goDetail(item)"
            v-for="item in indexList"
            :key="item.id"
          >
            <div class="img_box">
              <img
                :src="item.resources_url_host + item.resources_cover_pic"
                alt=""
              />
            </div>
            <div class="tt">{{ item.resources_name }}</div>
            <!-- <div class="img_bm">
              <img class="img1" src="../assets/img/yanjing.png" alt="" />
              <span>12</span>
              <img class="img2" src="../assets/img/xiazai.png" alt="" />
              <span>12</span>
              <img class="img3" src="../assets/img/zhuanfa.png" alt="" />
              <span>12</span>
            </div> -->
          </div>
        </div>
        <div class="pagina">
          <el-pagination
            :page-size="limit"
            :current-page="page"
            @current-change="handleCurrentChange"
            background
            layout="prev, pager, next"
            :total="total1"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="foot">
      <div class="box">
        <div class="top">
          <span>友情链接：文秘专业资源库</span>
          <!-- <span>|</span>
          <span>河南经贸职业学校</span> -->
          <span>|</span>
          <span>国家教学资源库</span>
          <span>|</span>
          <span>mooc</span>
          <span>|</span>
          <span>意见反馈</span>
        </div>
        <div class="bm">中文速录1+x</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      resources_name: '',
      limit: 8,
      page: 1,
      document_type: '0',
      sort: 1,
      order: 1,
      // 媒体类型
      document_typeObj: {},
      indexList: [],
      total1: 0,
    }
  },
  mounted() {
    this.sourcetype()
    this.index()
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val
      this.index()
    },
    goLogin() {
      this.$router.push('/login')
    },
    goRegister() {
      this.$router.push('/register')
    },
    // 跳转到首页
    goIndex() {
      this.$router.push('/')
    },
    // 跳转到资源详情
    goDetail(item) {
      this.$router.push('/centerDetail?item=' + JSON.stringify(item))
    },
    // 公共资源列表
    async index() {
      const { data: res } = await this.$http.get(
        'index.php/api/resources/resources/pubindex',
        {
          params: {
            resources_name: this.resources_name,
            document_type: this.document_type,
            sort: this.sort,
            order: this.order,
            limit: this.limit,
            page: this.page,
          },
        }
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.indexList = res.data.list
      this.total1 = res.data.total
    },
    // 搜索
    sousuo() {
      this.page = 1
      this.index()
    },
    // 排序字段变化事件
    sortChange(e) {
      this.sort = e
      this.page = 1
      this.index()
    },
    // 媒体类型改变事件
    document_typeChange(e) {
      this.document_type = e
      this.page = 1
      this.index()
    },
    // 资源类型和难度列表 和媒体类型
    async sourcetype() {
      const { data: res } = await this.$http.get(
        'index.php/api/resources/resources/sourcetype'
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.document_typeObj = res.data.document_type
    },
  },
}
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 110px;
  background: #538eff;

  .header_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @font-face {
      font-family: myFirstFont;
      src: url('../assets/FZXKJW.TTF');
    }
    .title {
      font-size: 48px;
      font-family: FZXingKai-S04S;
      color: #ffffff;
      font-family: myFirstFont;
    }
    .header_rt_box {
      display: flex;
      align-items: center;
      color: #ffffff;
      div:nth-child(1) {
        width: 73px;
        height: 37px;
        border: 1px solid #ffffff;
        border-radius: 15px;
        font-size: 19px;
        text-align: center;
        line-height: 37px;
        cursor: pointer;
      }
      div:nth-child(2) {
        width: 73px;
        height: 37px;
        background: #ffffff;
        border-radius: 15px;
        margin-left: 30px;
        font-size: 19px;
        text-align: center;
        line-height: 37px;
        color: #538eff;
        cursor: pointer;
      }
    }
  }
}
.nav {
  width: 100%;
  height: 60px;
  background: #ffffff;

  .nav_box {
    display: flex;
    align-items: center;
    font-size: 20px;
    div {
      height: 58px;
      border-bottom: 2px solid #ffffff;
      padding: 0 4px;
      line-height: 60px;
      cursor: pointer;
    }
    div:nth-child(2) {
      margin-left: 46px;
    }
    .active {
      border-bottom: 2px solid #538eff;
    }
  }
}
.zhong {
  width: 100%;
  background-color: #f1f4f8;
  overflow: hidden;
  .type {
    width: 1310px;
    margin: 28px auto;
    background-color: #fff;
    padding: 30px 50px;
    .type_box {
      display: flex;
      align-items: center;
      .title {
        color: #666666;
        font-size: 20px;
      }
      .box {
        color: #333333;
        font-size: 18px;
        span {
          margin-left: 40px;
        }
        .active {
          color: #538eff;
        }
      }
    }
  }
  .list {
    width: 1310px;
    margin: 0 auto;
    background-color: #fff;
    padding: 30px 50px;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      .top_lt {
        display: flex;
        align-items: center;
        div {
          margin-right: 52px;
          font-size: 18px;
          color: #333333;
          padding-bottom: 20px;
          border-bottom: 2px solid #ffffff;
        }
        .active {
          color: #538eff;
          border-bottom: 2px solid #538eff;
        }
      }
      .top_rt {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        .total {
          white-space: nowrap;
          margin-right: 25px;
          span {
            color: #538eff;
          }
        }
      }
    }
    .bm {
      display: flex;
      flex-wrap: wrap;
      .box {
        width: 290px;
        margin: 0 16px 70px 0;
        border-radius: 6px;
        overflow: hidden;
        cursor: pointer;
        .img_box {
          width: 290px;
          height: 181px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .tt {
          font-size: 20px;
          padding: 25px 0 15px 0;
        }
        .img_bm {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          img {
            margin: 0 3px 0 15px;
          }
          .img1 {
            width: 15px;
            height: 11px;
            display: block;
          }
          .img2 {
            width: 12px;
            height: 14px;
            display: block;
          }
          .img3 {
            width: 12px;
            height: 11px;
            display: block;
          }
          span {
            color: #333333;
          }
        }
      }
      .box:nth-child(4n) {
        margin: 0 0 70px 0;
      }
    }
  }
  .pagina {
    text-align: center;
    padding: 20px;
  }
}

.foot {
  width: 100%;
  height: 234px;
  background-color: #39404e;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
  overflow: hidden;
  span {
    margin: 0 10px;
  }
  .top {
    margin: 54px 0 45px;
  }
}
</style>