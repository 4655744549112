<template>
  <div>
    <div class="tt">
      练习名称：<span>{{ exercise_name }}</span>
    </div>
    <div class="tt">
      所属老师：<span>{{ teacher_ame }}</span>
    </div>
    <div class="tt">
      发布时间：<span>{{ add_date }}</span>
    </div>
    <div class="box">
      <div v-if="exercise_description != 4">
        <div class="title">题目：</div>
        <div v-if="!video_url" class="text" v-html="exercise_content"></div>
        <!-- 音频 -->
        <div v-if="video_url">
          <video width="400" height="55" controls>
            <source :src="URL + video_url" />
            您的浏览器不支持 video 标签。
          </video>
        </div>
      </div>
      <div v-else></div>
      <div>
        <div class="title">作答：</div>
        <el-input
          class="ipt2"
          type="textarea"
          :autosize="{ minRows: 12 }"
          placeholder="请输入内容"
          v-model="answer"
        >
        </el-input>
      </div>
    </div>
    <div class="btn" v-if="exercise_type == 2 && exercise_description != 4">
      <el-button size="small" type="primary" @click="tijiao"
        >提交练习</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      exercise_no: '',
      answer: '',
      exercise_name: '',
      teacher_ame: '',
      add_date: '',
      exercise_content: '',
      video_url: '',
      URL: '',
      exercise_type: '',
      exercise_description: ''
    }
  },
  mounted() {
    this.URL = this.$URL
    this.exercise_no = this.$route.query.exercise_no
    this.date = this.$route.query.date
    this.index()
  },
  methods: {
    // 学生-查看练习详情 /index.php/api/exercise/exercise/studentexercisedetail
    async index() {
      const { data: res } = await this.$http.get(
        'index.php/api/exercise/exercise/studentexercisedetail',
        {
          params: {
            exercise_no: this.exercise_no,
          },
        }
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.exercise_description = res.data.exercise_description
      if(res.data.exercise_description == 4) {

      }
      this.exercise_name = res.data.exercise_name
      this.teacher_ame = res.data.teacher_ame
      this.add_date = res.data.add_date
      this.exercise_content = res.data.exercise_content.replace(/\n/g, '<br/>')
      this.video_url = res.data.video_url
      this.exercise_type = res.data.exercise_type
      if (res.data.my_answer.toString()) {
        this.answer = res.data.my_answer.answer
      }
    },
   
    // 学生-提交练习 /index.php/api/exercise/exercise/studentexercisecommit
    async tijiao() {
      if (!this.answer) return this.$message.error('请您上传附件')
      const { data: res } = await this.$http.post(
        'index.php/api/exercise/exercise/studentexercisecommit',
        {
          exercise_no: this.exercise_no,
          answer: this.answer,
          use_time: (new Date().getTime = this.date),
        }
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.index()
    },
  },
}
</script>

<style lang="less" scoped>
.tt {
  font-size: 16px;
  color: #535454;
  margin-bottom: 20px;
  span {
    color: #98989e;
  }
}
.box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 499px;
  background: #ffffff;
  // border: 2px solid #f1f4f8;
  // box-shadow: 3px 3px 10px 7px rgba(162, 162, 162, 0.15);
  // overflow-y: scroll;
  padding: 20px;
  .title {
    font-size: 16px;
    color: #98989e;
    margin-bottom: 10px;
  }
  .text {
    width: 500px;
    max-height: 400px;
    overflow-y: auto;
  }
  .ipt2 {
    width: 440px;
  }
}
.btn {
  margin: 30px auto;
  text-align: center;
}
</style>