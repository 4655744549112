<template>
  <div class="box">
    <el-form
      ref="form"
      :model="form"
      label-width="90px"
      size="small"
      :rules="rules"
    >
      <el-form-item label="姓名: " prop="nickname">
        <el-input class="ipt" v-model="form.nickname"></el-input>
      </el-form-item>
      <el-form-item label="性别: ">
        <el-radio-group v-model="form.gender">
          <el-radio :label="1">男</el-radio>
          <el-radio :label="0">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="头像: ">
        <el-upload
          class="avatar-uploader"
          :action="URL + 'index.php/api/common/upload'"
          :headers="headers"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
        >
          <img v-if="form.avatar" :src="URL + form.avatar" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="edit_btn">编辑</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        nickname: '',
        gender: 1,
        avatar: '',
      },
      rules: {
        nickname: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
      },
      URL: '',
      imgUrl: '',
      headers: {
        token: '',
      },
    }
  },
  mounted() {
    this.URL = this.$URL
    this.imgUrl = this.$imgUrl
    this.headers.token = window.localStorage.getItem('student_token')
    this.user_no = window.localStorage.getItem('user_no_student')
    this.userinfo()
  },
  methods: {
    // 上传资源图片成功
    handleAvatarSuccess(res, file) {
      if (res.code != 1) return this.$message.error(res.msg)
      this.form.avatar = res.data.url
    },
    // 根据编号获取用户信息 /index.php/api/user/userinfo
    async userinfo() {
      const { data: res } = await this.$http.get(
        'index.php/api/user/userinfo',
        {
          params: {
            no: this.user_no,
          },
        }
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.form = {
        nickname: res.data.nickname,
        gender: res.data.gender,
        avatar: res.data.avatar,
      }
    },
    // 编辑
    edit_btn() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整数据！')
        const { data: res } = await this.$http.post(
          'index.php/api/user/profile',
          this.form
        )
        if (res.code != 1) return this.$message.error(res.msg)
        this.$message.success(res.msg)
      })
    },
  },
}
</script>

<style lang="less" scoped>
.box {
  padding: 20px 0 0 0;
  .ipt {
    width: 400px;
  }
}
</style>