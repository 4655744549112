import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element'


// 导入字体图标
import './assets/font/iconfont.css'

// 导入全局样式表
import './assets/css/global.css'

// 先引入axios 文件
import axios from 'axios'
//设置默认的请求地址
// const url = 'https://1xsub2.zzyzwl.com/'
const url = link + '/'

axios.defaults.baseURL = url
//挂载到vue上面，可以直接this.$http  使用
Vue.prototype.$http = axios
Vue.prototype.$URL = url
Vue.prototype.$imgUrl = url.substring(0, url.length - 1)
//设置拦截器
axios.interceptors.request.use(config => {
  //登录成功后设置token信息为Authorization  才能使用这个拦截器
  config.headers.token = window.localStorage.getItem('student_token')
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
})
axios.interceptors.response.use(response => {
  // 对响应数据做点什么
  if (response.data.code === 199) {
    window.localStorage.removeItem('student_token')
    router.push('/')
    return
  }

  return response
}, function (error) {
  // 对响应错误做点什么
  console.log(2, error)
  return Promise.reject(error);
});


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
