<template>
  <div>
    <div class="center">
      <div class="gonggao">
        <div class="comment">
          <div class="lt">
            <div class="lt_top">
              <div>{{ item.notice_name }}</div>
              <!-- <img src="../assets/img/dian1.png" alt="" /> -->
            </div>
            <div class="lt_content">
              已读：{{ item.read_num }}/{{ item.count_num }}
            </div>
            <div class="lt_xian"></div>
            <div class="lt_tt">{{ item.notice_content }}</div>
          </div>
          <div class="lt">
            <div class="lt_top">
              <div>评论（{{ total }}）</div>
              <!-- <img src="../assets/img/dian1.png" alt="" /> -->
            </div>
            <div class="lt_xian"></div>
            <div class="lt_ipt">
              <img src="../assets/img/user3.png" alt="" />
              <el-input
                class="ipt"
                type="textarea"
                :rows="3"
                placeholder="请输入内容"
                size="small"
                v-model="comment_content"
                clearable
              >
              </el-input>
              <el-button type="primary" size="small" @click="fasong_btn"
                >发送</el-button
              >
            </div>
          </div>
          <div class="pinglun" v-for="item in commentlist" :key="item.id">
            <div>
              <div class="teacher_box">
                <span  class="teacher">评论时间：{{item.comment_date}}</span>
                <span>评论者：{{item.nickname}}</span>
              </div>
              <div>
                {{ item.comment_content }}
              </div>
            </div>
            <div class="del" @click="del(item.id)" v-if="item.can_del == 1">
              删除
            </div>
          </div>
          <div class="pagina">
            <el-pagination
              :page-size="limit"
              background
              @current-change="handleCurrentChange"
              layout="prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notice_id: '',
      page: 1,
      limit: 10,
      total: 0,
      commentlist: '',
      comment_content: '',
      item: {},
    }
  },
  mounted() {
    const item = JSON.parse(this.$route.query.item)
    if (item) {
      this.item = item
      this.notice_id = item.id
      this.comment()
      this.studentupreadnotice()
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val
      this.comment()
    },
    // 学生更新公告已读 /index.php/api/notice/notice/studentupreadnotice
    async studentupreadnotice() {
      const { data: res } = await this.$http.get(
        'index.php/api/notice/notice/studentupreadnotice',
        {
          params: {
            notice_id: this.notice_id,
          },
        }
      )
      if (res.code != 1) return this.$message.error(res.msg)
    },
    // 公告评论列表 /index.php/api/notice/notice/commentlist
    async comment() {
      const { data: res } = await this.$http.get(
        'index.php/api/notice/notice/commentlist',
        {
          params: {
            notice_id: this.notice_id,
            limit: this.limit,
            page: this.page,
          },
        }
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.commentlist = res.data.list
      this.total = res.data.total
    },
    // 评论公告 /index.php/api/notice/notice/addcomment
    async fasong_btn() {
      if (!this.comment_content) return this.$message.error('请填写完整数据！')
      const { data: res } = await this.$http.get(
        'index.php/api/notice/notice/addcomment',
        {
          params: {
            notice_id: this.notice_id,
            comment_content: this.comment_content,
          },
        }
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.comment_content = ''
      this.comment()
    },
    // 评论人删除公告评论 /index.php/api/notice/notice/delcomment
    async del(id) {
      this.$confirm('此操作将删除该评论, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const { data: res } = await this.$http.get(
            'index.php/api/notice/notice/delcomment',
            {
              params: {
                id: id,
              },
            }
          )
          if (res.code != 1) return this.$message.error(res.msg)
          this.$message.success(res.msg)
          this.comment()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
  },
}
</script>

<style lang="less" scoped>
.center {
  width: 1040px;
  color: #333;
  .gonggao {
    padding: 0px 0 10px;
    .comment {
      // display: flex;
      // justify-content: space-between;
      .lt {
        // width: 540px;
        .lt_top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 22px;
          color: #666666;
          padding: 0 30px 10px;
          img {
            width: 5px;
            height: 25px;
            display: block;
          }
        }
        .lt_content {
          font-size: 14px;
          color: #538eff;
          padding: 0 30px 20px;
        }
        .lt_xian {
          // width: 541px;
          height: 1px;
          border: 1px solid #b8b9b9;
          opacity: 0.35;
        }
        .lt_tt {
          font-size: 22px;
          color: #666666;
          padding: 20px 30px 40px;
        }
        .lt_ipt {
          display: flex;
          align-items: center;
          padding: 30px 30px 20px;
          img {
            width: 42px;
            height: 42px;
            display: block;
            margin-right: 5px;
          }
          .ipt {
            width: 300px;
          }
        }
      }
      .pinglun {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #edf4fe;
        padding: 15px;
        font-size: 14px;
        .teacher_box {
          margin-bottom: 10px;
          color: rgb(153, 153, 153);
          font-size: 15px;
          .teacher {
            margin-right: 40px;
          }
        }
        .del {
          color: #f56c6c;
          font-size: 14px;
          cursor: pointer;
          margin-left: 100px;
          min-width: 30px;
        }
      }
    }
  }
}
.foot {
  width: 100%;
  height: 90px;
  background-color: #538eff;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c7e3fe;
  div {
    margin: 0 26px;
  }
}
.ipt {
  width: 600px !important;
  margin-right: 20px;
}
.pagina {
  text-align: center;
  padding: 20px;
}
</style>