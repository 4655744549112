import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Home from '../views/Home.vue'
import Index from '../views/Index.vue'
import Center from '../views/Center.vue'
import CenterDetail from '../views/CenterDetail.vue'

// 公共资源列表
import PublicResource from '../views/PublicResource.vue'
// 公共资源收藏列表
import PublicCollection from '../views/PublicCollection.vue'
// 资源详情
import PublicDetail from '../views/PublicDetail.vue'

// 资源标签
import Label from '../views/Label.vue'
// 教学管理
// 我的课堂
import Teaching from '../views/Teaching.vue'
// 我的班级
import Class from '../views/Class.vue'
// 作业记录
import Operation from '../views/Operation.vue'
// 作业详情
import OperationDetail from '../views/OperationDetail.vue'
// 考试记录
import Exam from '../views/Exam.vue'
// 考试详情
import OperationDetail_ks from '../views/OperationDetail_ks.vue'
// 标准练习
import Standard from '../views/Standard.vue'
// 标准练习详情（开始练习）单选多选判断
import StandardDetail from '../views/StandardDetail.vue'
// 标准练习详情（开始练习）文本
import StandardDetailtext from '../views/StandardDetailtext.vue'
// 自由练习
import Free from '../views/Free.vue'
// 公告
import Log from '../views/Log.vue'
// 公告评论列表
import Announcement from '../views/Announcement.vue'
// 个人中心
// 修改个人信息
import User from '../views/User.vue'
// 修改邮箱
import EditEmail from '../views/EditEmail.vue'
// 修改密码
import EditPassword from '../views/EditPassword.vue'
// 直播文字
import Text from '../views/Text.vue'
import TextDetail from '../views/TextDetail.vue'
// 区块文字
import Block from '../views/Block.vue'
import Block1 from '../views/Block1.vue'
import Block2 from '../views/Block2.vue'
import Block3 from '../views/Block3.vue'


// const About = () => import(/* webpackChunkName: "about" */ '../views/About.vue')

Vue.use(VueRouter)

const routes = [
  
  { path: '/', component: Index },
  { path: '/center', component: Center },
  { path: '/centerDetail', component: CenterDetail},
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/register',
    component: Register,
  },
  { path: '/textDetail', component: TextDetail},
  {
    path: '/home',
    component: Home,
    redirect: '/teacher',
    children: [
      { path: '/publicResource', component: PublicResource},
      { path: '/publicCollection', component: PublicCollection},
      { path: '/publicDetail', component: PublicDetail},
      { path: '/label', component: Label },
      { path: '/teaching', component: Teaching },
      { path: '/class', component: Class},
      { path: '/operation', component: Operation },
      { path: '/operationDetail',component: OperationDetail},
      { path: '/exam', component: Exam},
      { path: '/operationDetail_ks', component: OperationDetail_ks},
      { path: '/standard', component: Standard},
      { path: '/standardDetail', component: StandardDetail},
      { path: '/standardDetailtext', component: StandardDetailtext},
      { path: '/free', component: Free},
      { path: '/log', component: Log },
      { path: '/announcement', component: Announcement},
      { path: '/user', component: User},
      { path: '/editEmail', component: EditEmail},
      { path: '/editPassword', component: EditPassword},
      { path: '/text', component: Text},
      { path: '/block', component: Block},
      { path: '/block1', component: Block1},
      { path: '/block2', component: Block2},
      { path: '/block3', component: Block3}


    ]
  }
  
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  // from 代表从哪个路径跳转而来
  // next 是一个函数，表示放行
  //     next()  放行    next('/login')  强制跳转
  const token = window.localStorage.getItem('student_token') || ''
  const name = window.localStorage.getItem('username_student') || ''
  // if(to.path == '/') return next()
  if (token && (to.path == '/' || to.path == '/center' || to.path == '/login' || to.path == '/centerDetail' || to.path == '/register')) {
    window.localStorage.setItem('activePath_student', '/teaching')
    return next(`/teaching?name=${name}&token=${token}`)
  }
  if (!token && to.path != '/' && to.path != '/center' && to.path != '/login'  && to.path != '/centerDetail' && to.path != '/register') return next('/')
  next()
})
export default router
