<template>
  <div>
    <div class="serve">
      <div class="serve_box">
        <span>作业名称：</span>
        <el-input
          size="small"
          class="ipt"
          placeholder="请输入内容"
          v-model="homework_name"
          clearable
        >
        </el-input>
        <el-button size="small" type="primary" @click="sousuo">搜索</el-button>
      </div>
    </div>
    <el-table :data="indexList" stripe style="width: 100%">
      <el-table-column type="index" label="序号"> </el-table-column>
      <el-table-column prop="homework_name" label="作业名称"> </el-table-column>
      <el-table-column prop="teacher_name" label="所属教师"> </el-table-column>
      <el-table-column prop="take_effect_date" label="发送时间"> </el-table-column>
      <el-table-column prop="answer_time" label="提交时间"> </el-table-column>
      <el-table-column label="作业状态">
        <template v-slot="scoped">
          <div class="answer_type" v-if="scoped.row.is_answer == 0">未提交</div>
          <div class="answer_type1" v-else>已提交</div>
        </template>
       </el-table-column>
      <el-table-column label="操作" width="110px">
        <template v-slot="scoped">
          <el-button
            @click="chakan(scoped.row)"
            size="mini"
            type="primary"
          >查看作业</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagina">
      <el-pagination
        @current-change="handleCurrentChange"
        background
        :page-size="limit"
        :current-page="page"
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      homework_name: '',
      limit: 10,
      page: 1,
      indexList: [],
      total: 0,
    }
  },
  mounted() {
    this.index()
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val
      this.index()
    },
    handleClose() {
      this.dialogVisible = false
      this.$refs.form.resetFields()
      this.form = {
        class_name: '',
        is_delete: 0,
        id: '',
      }
      this.id = ''
    },
    // 学生-查看作业列表 /index.php/api/homework/homework/studenthomeworklist
    async index() {
      const { data: res } = await this.$http.get(
        'index.php/api/homework/homework/studenthomeworklist',
        {
          params: {
            homework_name: this.homework_name,
            limit: this.limit,
            page: this.page,
          },
        }
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.indexList = res.data.list
      this.total = res.data.total
    },
    // 搜索
    sousuo() {
      this.page = 1 
      this.index()
    },
    
    // 查看作业
    chakan(row) {
      this.$router.push('/operationDetail?homework_no=' + row.homework_no)
    },
  },
}
</script>

<style lang='less' scoped>
.serve {
  margin-bottom: 30px;
  display: flex;
  .serve_box {
    display: flex;
    align-items: center;
    margin-right: 30px;
    span {
      color: #535454;
      font-size: 14px;
    }
    .ipt {
      width: 220px;
    }
  }
}
.answer_type {
  color: #FF0000;
}
.answer_type1 {
  color: #27B64F;
}
.pagina {
  text-align: center;
  padding: 20px;
}
.ipt {
  width: 400px;
}
</style>