var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},_vm._l((_vm.indexList),function(item,i){return _c('div',{key:i,staticClass:"box1"},[_c('div',{staticClass:"text"},[(i == 0)?_c('span',[_vm._v("一，")]):_vm._e(),(i == 1)?_c('span',[_vm._v("二，")]):_vm._e(),(i == 2)?_c('span',[_vm._v("三，")]):_vm._e(),(i == 3)?_c('span',[_vm._v("四，")]):_vm._e(),_vm._v(" "+_vm._s(item.title)),_c('span',{staticClass:"span1"},[_vm._v("（"+_vm._s(item.listQues.length)+"道）")])]),(
        item.questions_class == 'radio' || item.questions_class == 'decide'
      )?_c('div',_vm._l((item.listQues),function(it,e){return _c('div',{key:e,staticClass:"list"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(i + 1)+"."+_vm._s(it.title))]),_vm._l((it.listAnswer),function(it1,e1){return _c('div',{key:e1,staticClass:"radio_box"},[_c('el-radio',{attrs:{"label":it1.question_answer_id},model:{value:(it.member_question_answer),callback:function ($$v) {_vm.$set(it, "member_question_answer", $$v)},expression:"it.member_question_answer"}},[_vm._v(_vm._s(it1.title)+" ")])],1)}),_c('div',{staticClass:"list_btn"},[_c('div',{staticClass:"btn_rt1"},[_c('div',[_vm._v("正确答案：")]),_vm._l((it.listAnswer),function(it1,e1){return _c('div',{key:e1},[(it.system_right_answer == it1.question_answer_id)?_c('span',[_vm._v(_vm._s(it1.title))]):_vm._e()])})],2),_c('div',[_c('div',{staticClass:"btn_lt"},[_c('div',[_vm._v("考生答案：")]),_vm._l((it.listAnswer),function(it1,e1){return _c('div',{key:e1},[(it.member_question_answer == it1.question_answer_id)?_c('span',{class:it.system_right_answer != it.member_question_answer
                      ? 'span'
                      : ''},[_vm._v(_vm._s(it1.title))]):_vm._e()])})],2)])])],2)}),0):_vm._e(),(item.questions_class == 'checkbox')?_c('div',_vm._l((item.listQues),function(it,e){return _c('div',{key:e,staticClass:"list"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(i + 1)+"."+_vm._s(it.title))]),_vm._l((it.listAnswer),function(it1,e1){return _c('div',{key:e1,staticClass:"radio_box"},[_c('el-checkbox',{attrs:{"value":it.system_right_answer.includes(it1.question_answer_id)}},[_vm._v(_vm._s(it1.title))])],1)}),_c('div',{staticClass:"list_btn"},[_c('div',{staticClass:"btn_rt1"},[_c('div',[_vm._v("正确答案：")]),_vm._l((it.listAnswer),function(it1,e1){return _c('div',{key:e1},[(it.system_right_answer.includes(it1.question_answer_id))?_c('span',[_c('span',[_vm._v(_vm._s(it1.title)+"，")])]):_vm._e()])})],2),_c('div',[_c('div',{staticClass:"btn_lt"},[_c('div',[_vm._v("考生答案：")]),_vm._l((it.listAnswer),function(it1,e1){return _c('div',{key:e1},[(it.member_question_answer.includes(it1.question_answer_id))?_c('span',{class:it.system_right_answer != it.member_question_answer
                      ? 'span'
                      : ''},[_c('span',[_vm._v(_vm._s(it1.title)+"，")])]):_vm._e()])})],2)])])],2)}),0):_vm._e(),(
        item.questions_class == 'listen' || item.questions_class == 'answer'
      )?_c('div',_vm._l((item.listQues),function(it,e){return _c('div',{key:e,staticClass:"list"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(i + 1)+"."+_vm._s(it.title))]),_vm._l((it.listAnswer),function(it1,e1){return _c('div',{key:e1,staticClass:"listen_box"},[(item.questions_class == 'listen')?_c('video',{attrs:{"width":"700","height":"65","controls":""}},[_c('source',{attrs:{"src":it1.audio}}),_vm._v(" 您的浏览器不支持 video 标签。 ")]):_vm._e()])}),_c('div',[_c('div',{staticClass:"btn_zq"},[_vm._v("正确答案：")]),_c('div',{staticClass:"tt1"},[_vm._v(" "+_vm._s(it.system_right_answer)+" ")])]),_c('div',[_c('div',{staticClass:"btn_ks"},[_vm._v("考生答案：")]),_c('div',{class:it.system_right_answer != it.member_question_answer
                ? 'tt'
                : 'tt1'},[_vm._v(" "+_vm._s(it.member_question_answer)+" ")])])],2)}),0):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }