<template>
  <div>
    <div class="center">
      <div class="gonggao">
        <div class="ipt_box">
          <div class="ipt_lt">
            <el-input
              class="ipt"
              placeholder="请输入内容"
              size="small"
              v-model="notice_name"
              clearable
            >
            </el-input>
            <el-button type="primary" size="small" @click="chanxun">查询</el-button>
          </div>
        </div>
        <div
          class="list"
          @click="goannoun(item)"
          v-for="(item, i) in noticeList"
          :key="i"
        >
          <div class="list_tp">
            <div></div>
            <div>{{ item.notice_name }}</div>
          </div>
          <div class="list_content">
            <span>发布时间：{{ item.add_date }}</span>
            <span>发布者：{{ item.nickname }}</span>
          </div>
          <div class="list_tt">
            {{ item.notice_content }}
          </div>
          <div class="list_bm">
            <span>已读：{{ item.read_num }}/{{ item.count_num }}</span>
            <span>评论：{{ item.comment_num }}</span>
          </div>
        </div>
        <div class="pagina">
          <el-pagination
            :page-size="limit_gg"
            :current-page="page_gg"
            background
            @current-change="handleCurrentChange_gg"
            layout="prev, pager, next"
            :total="total_gg"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 公告参数
      notice_name: '',
      class_id: '',
      limit_gg: 4,
      page_gg: 1,
      noticeList: [],
      total_gg: 0,
    }
  },
  mounted() {
    this.notice()
  },
  methods: {
    handleCurrentChange_gg(val) {
      this.page_gg = val
      this.notice()
    },
    // 学生获取公告列表 /index.php/api/notice/notice/indexstudentnotice
    async notice() {
      const { data: res } = await this.$http.get(
        'index.php/api/notice/notice/indexstudentnotice',
        {
          params: {
            notice_name: this.notice_name,
            limit: this.limit_gg,
            page: this.page_gg,
          },
        }
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.noticeList = res.data.list
      this.total_gg = res.data.total
    },
    chanxun() {
      this.page_gg = 1 
      this.notice()
    },
    // 跳转到评论列表
    goannoun(item) {
      this.$router.push('/announcement?item=' + JSON.stringify(item))
    },
  },
}
</script>

<style lang="less" scoped>
.center {
  width: 1040px;
  color: #333;
  .gonggao {
    padding: 0px 0 10px;
    .ipt_box {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .ipt_lt {
        .ipt {
          width: 300px;
        }
      }
      .fabu {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 17px;
        width: 150px;
        height: 41px;
        background: #538eff;
        border: 1px solid #75a5ff;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        img {
          width: 24px;
          height: 23px;
          display: block;
          margin-right: 8px;
        }
      }
    }
    .list {
      padding: 20px;
      cursor: pointer;
      position: relative;
      .list_tp {
        display: flex;
        align-items: center;
        font-size: 22px;
        color: #666666;
        // div:nth-child(1) {
        //   width: 11px;
        //   height: 11px;
        //   background: #f91616;
        //   border-radius: 50%;
        //   margin-right: 10px;
        // }
      }
      .list_content {
        color: rgb(153, 153, 153);
        padding: 20px 0 10px;
        span {
          margin-right: 20px;
        }
      }
      .list_tt {
        margin-bottom: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .list_bm {
        color: #999;
        span {
          margin-right: 20px;
        }
      }
      .del {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 14px;
        color: #f56c6c;
      }
    }
    .list:hover {
      background-color: #edf4fe;
    }
    .no {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 80px 0 40px;
      div:nth-child(1) {
        width: 55px;
        height: 1px;
        border: 1px solid #b8b9b9;
      }
      div:nth-child(2) {
        font-size: 22px;
        color: #999999;
        margin: 0 20px;
      }
      div:nth-child(3) {
        width: 55px;
        height: 1px;
        border: 1px solid #b8b9b9;
      }
    }
    .comment {
      display: flex;
      justify-content: space-between;
      .lt {
        width: 540px;
        .lt_top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 22px;
          color: #666666;
          padding: 0 30px;
          img {
            width: 5px;
            height: 25px;
            display: block;
          }
        }
        .lt_content {
          font-size: 14px;
          color: #538eff;
          padding: 8px 30px 20px;
        }
        .lt_xian {
          width: 541px;
          height: 1px;
          border: 1px solid #b8b9b9;
          opacity: 0.35;
        }
        .lt_tt {
          font-size: 22px;
          color: #666666;
          padding: 20px 30px;
        }
        .lt_ipt {
          display: flex;
          align-items: center;
          padding: 30px 30px 0;
          img {
            width: 42px;
            height: 42px;
            display: block;
            margin-right: 5px;
          }
          .ipt {
            width: 300px;
          }
        }
      }
    }
  }
}
.ipt {
  width: 400px;
}
.pagina {
  text-align: center;
  padding: 20px;
}
</style>