<template>
  <div>
    <el-form
        ref="form"
        label-width="120px"
        size="small"
      >
        <el-form-item label="作业名称: ">
          <div>{{homework_name}}</div>
        </el-form-item>
        <el-form-item label="所属教师: ">
          <div>{{teacher_name}}</div>
        </el-form-item>
        <el-form-item label="截止时间: ">
          <div>{{end_time}}</div>
        </el-form-item>
        <el-form-item label="作业题目: ">
          <!-- 音频 -->
          <div v-if="homework_type == 3">
              <video width="520" height="100" controls>
                  <source :src="resources_url">
                  您的浏览器不支持 video 标签。
              </video>
          </div>
          <!-- 视频 -->
          <div v-if="homework_type == 4">
            <video width="520" height="340" controls>
                  <source :src="resources_url">
                  您的浏览器不支持 video 标签。
              </video>
            
          </div>
         
           <!-- 文本 -->
          <div v-if="homework_type == 5 && resources_url.slice(-3) == 'pdf'">
            <iframe
              width="640"
              height="400"
              :src="resources_url"
              frameborder="0"
            ></iframe>
          </div>
          <div v-if="homework_type == 5 && resources_url.slice(-3) != 'pdf'">
            <iframe
              width="640"
              height="400"
              :src="
                'https://view.officeapps.live.com/op/view.aspx?src=' +
                resources_url
              "
              frameborder="0"
            ></iframe>
          </div>
          <div v-if="homework_type == 6">
            <div>请前往考试系统考试</div>
          </div>
        </el-form-item>
        <el-form-item label="提交附件：">
          <el-upload
            class="ipt"
            :action="URL + 'index.php/api/common/upload'"
            :headers="headers"
            :on-remove="handleRemove"
            :on-success="handleAvatarSuccessUrl"
            :limit="1"
            :file-list="fileList"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传word/pdf文件</div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="add_btn"
            >提交作业</el-button
          >
        </el-form-item>
      </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      homework_no: '',
      answer: '',
     
      homework_name: '',
      teacher_name: '',
      end_time: '',
      homework_type: 1,
      resources_url: '',
      fileList: [],
      URL: '',
      headers: {
        token: '',
      },
    }
  },
  mounted(){
    this.URL = this.$URL
    this.headers.token = window.localStorage.getItem('student_token')
    this.homework_no = this.$route.query.homework_no
    this.homeworkdetailstudent()
  },
  methods: {
    // 学生-作业详情 /index.php/api/homework/homework/homeworkdetailstudent
    async homeworkdetailstudent(homework_no) {
      const { data: res } = await this.$http.get(
        'index.php/api/homework/homework/homeworkdetailstudent',
        {
          params: {
            homework_no: this.homework_no,
          },
        }
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.homework_name = res.data.homework_name
      this.teacher_name = res.data.teacher_name
      this.end_time = res.data.end_time
      this.homework_type = res.data.homework_type
      this.resources_url = res.data.resources_host + res.data.resources_url
      if(res.data.student_answer) {
        this.answer = res.data.student_answer.answer
        this.fileList = [{name:res.data.student_answer.answer, url: res.data.student_answer.answer}]
      }
      
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
      this.answer = ''
      this.fileList = []
    },
    // 上传资源地址成功
    handleAvatarSuccessUrl(res, file) {
      if (res.code != 1) return this.$message.error(res.msg)
      console.log(res)
      this.answer = res.data.url
    },
    // 学生-提交作业 /index.php/api/homework/homework/homeworkdetailstudent
    async add_btn() {
      if(!this.answer) return this.$message.error('请您上传附件')
      const { data: res } = await this.$http.post(
          'index.php/api/homework/homework/homeworkstudentcommit',
          {
            homework_no: this.homework_no,
            answer: this.answer
          }
        )
        if (res.code != 1) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
    }
  }
}
</script>

<style lang="less" scoped>
.ipt {
  width: 400px;
}

</style>