<template>
  <div class="box">
    <el-form
      ref="form"
      :model="form"
      label-width="90px"
      size="small"
      :rules="rules"
    >
      <el-form-item label="邮箱: " prop="email">
        <el-input class="ipt" v-model="form.email"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="edit_btn">编辑</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

export default {
 
  data() {
     var checkEmail = (rule, value, cb) => {
      const regMobile = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/
      if (regMobile.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法的邮箱'))
    }
    return {
      form: {
        email: '',
      },
      rules: {
        email: [{ required: true, message: '请输入邮箱', trigger: 'blur' },
        { validator: checkEmail, trigger: 'blur' }],
      },
     
    }
  },
  mounted() {
    this.user_no = window.localStorage.getItem('user_no_student')
    this.userinfo()
  },
  methods: {
    // 根据编号获取用户信息 /index.php/api/user/userinfo
    async userinfo() {
      const { data: res } = await this.$http.get(
        'index.php/api/user/userinfo',
        {
          params: {
            no: this.user_no,
          },
        }
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.form = {
        email: res.data.email,
      }
    },
    // 修改邮箱 /index.php/api/user/changeemail
    edit_btn() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整数据！')
        const { data: res } = await this.$http.post(
          'index.php/api/user/changeemail',
          this.form
        )
        if (res.code != 1) return this.$message.error(res.msg)
        this.$message.success(res.msg)
      })
    },
  },
}
</script>

<style lang="less" scoped>
.box {
  padding: 20px 0 0 0;
  .ipt {
    width: 400px;
  }
}
</style>