<template>
  <div>
    <div class="serve">
      <div class="serve_box">
        <span>上课时间：</span>
        <el-date-picker
          value-format="yyyy-MM-dd HH:mm:ss"
          size="small"
          v-model="starttime"
          type="datetime"
          placeholder="选择开始时间"
        >
        </el-date-picker>
        <span class="span">-</span>
        <el-date-picker
          value-format="yyyy-MM-dd HH:mm:ss"
          size="small"
          v-model="endtime"
          type="datetime"
          placeholder="选择结束时间"
        >
        </el-date-picker>
        <el-button size="small" type="primary" @click="sousuo">搜索</el-button>
      </div>
    </div>
    <div class="list">
      <div class="box" v-for="item in indexList" :key="item.id">
        <div class="box_user">
          <div class="box_user_top">
            <div class="user_img">
              <!-- <img src="../assets/img/user.png" alt="" />
              <div class="num">23</div> -->
              <div class="kecheng">课程ID: {{item.room_id}}</div>
              
            </div>
            <div class="user_tt" @click="shangke(item.room_id)">开始上课</div>
          </div>
          <div class="teacher">课程名称: {{item.course_name}}</div>
          <div class="teacher">教师: {{item.teacher_name}}</div>
          <div class="box_user_content">{{item.class_name}}</div>
          <div class="box_user_bm">
            <div>时间：{{item.course_time}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagina">
      <el-pagination
        @current-change="handleCurrentChange"
        background
        :page-size="limit"
        :current-page="page"
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      starttime: '',
      endtime: '',
      limit: 4,
      page: 1,
      total: 0,
      // 课程列表
      indexList: [],
      
    }
  },
  mounted() {
    this.index()
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val
      this.index()
    },
    // 课程列表
    async index() {
      const { data: res } = await this.$http.get(
        'index.php/api/student/course/index', {
          params: {
            starttime: this.starttime,
            endtime: this.endtime,
            limit: this.limit,
            page: this.page
          }
        }
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.indexList = res.data.list
      this.total = res.data.total
    },
    // 搜索
    sousuo() {
      this.page = 1
      this.index()
    },
    // 开始上课
    shangke(room_id) {
      const username = window.localStorage.getItem('username_student')
      this.$router.push('/teaching?room_id=' + room_id + '&nickname=' + username )
    }
    
  },
}
</script>
  
<style lang='less' scoped>
.serve {
  margin-bottom: 30px;
  display: flex;
  .serve_box {
    display: flex;
    align-items: center;
    margin-right: 30px;
    span {
      color: #535454;
      font-size: 14px;
    }
    .span {
      margin: 0 5px;
    }
    .ipt {
      width: 220px;
    }
  }
}
.list {
  display: flex;
  flex-wrap: wrap;
  .box {
    margin: 0 60px 50px 0;
    .list_nav {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      // width: 160px;
      margin-bottom: 10px;
      div {
        font-size: 18px;
        color: #555555;
      }
      i {
        cursor: pointer;
        margin-left: 30px;
      }
    }
    .box_user {
      width: 462px;
      height: 244px;
      background: #ffffff;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.11);
      padding: 20px 0 20px 20px;
      .box_user_top {
        display: flex;
        justify-content: space-between;
        .user_img {
          display: flex;
          align-items: center;
          img {
            width: 30px;
            height: 30px;
            display: block;
            margin-right: 20px;
          }
          .num {
            font-size: 16px;
            color: #777777;
            margin-right: 20px;
          }
          .kecheng {
            color: #777777;
          }
        }
        
        .user_tt {
          width: 109px;
          height: 35px;
          background-color: #538eff;
          text-align: center;
          line-height: 35px;
          font-size: 18px;
          color: #ffffff;
          cursor: pointer;
        }
      }
      .teacher {
        color: #777777;
        margin: 15px 0 0 0;
      }
      .box_user_content {
        font-size: 26px;
        color: #777777;
        padding: 30px 0 10px 0;
      }
      .box_user_bm {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        padding-right: 20px;
        color: #777777;
        .xq {
          color: #538eff;
          cursor: pointer;
        }
      }
    }
  }
  .box:nth-child(2n) {
    margin-right: 0;
  }
}
.pagina {
  text-align: center;
  padding: 20px;
}
.ipt {
  width: 400px;
}
</style>