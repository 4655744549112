<template>
  <div class="box">
    <el-form
      ref="form"
      :model="form"
      label-width="120px"
      size="small"
      :rules="rules"
    >
      <el-form-item label="旧密码: " prop="oldpassword">
        <el-input
          type="password"
          class="ipt"
          v-model="form.oldpassword"
        ></el-input>
      </el-form-item>
      <el-form-item label="新密码: " prop="newpassword">
        <el-input
          type="password"
          class="ipt"
          v-model="form.newpassword"
        ></el-input>
      </el-form-item>
      <el-form-item label="重复新密码: " prop="newpasswordagain">
        <el-input
          type="password"
          class="ipt"
          v-model="form.newpasswordagain"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="edit_btn">编辑</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.form.newpasswordagain !== '') {
          this.$refs.form.validateField('newpasswordagain')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.newpassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      form: {
        oldpassword: '',
        newpassword: '',
        newpasswordagain: '',
      },
      rules: {
        oldpassword: [
          { required: true, message: '请输入旧密码', trigger: 'blur' },
        ],
        newpassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' },
        ],
        newpasswordagain: [
          { required: true, message: '请重复新密码', trigger: 'blur' },
          { validator: validatePass2, trigger: 'blur' },
        ],
      },
    }
  },
  mounted() {},
  methods: {
    // 修改密码 /index.php/api/user/changepassword
    edit_btn() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整数据！')
        const { data: res } = await this.$http.post(
          'index.php/api/user/changepassword',
          this.form
        )
        if (res.code != 1) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.$refs.form.resetFields()
      })
    },
  },
}
</script>

<style lang="less" scoped>
.box {
  padding: 20px 0 0 0;
  .ipt {
    width: 400px;
  }
}
</style>