<template>
  <div>
    <div class="serve">
      <div class="serve_box">
        <span>分类名称：</span>
        <el-input
          size="small"
          class="ipt"
          placeholder="请输入内容"
          v-model="tag_name"
          clearable
        >
        </el-input>
        <el-button size="small" type="primary" @click="sousuo">搜索</el-button>
      </div>
    </div>
    <el-table :data="indexList" stripe style="width: 100%">
      <el-table-column type="index" label="序号"> </el-table-column>
      <el-table-column prop="tag_name" label="分类名称"> </el-table-column>
      <el-table-column prop="add_date" label="创建时间"> </el-table-column>
      <el-table-column prop="remarks" label="备注"> </el-table-column>
      <el-table-column prop="address" label="操作" width="100px">
        <template v-slot="scoped">
          <el-button size="mini" type="warning" @click="ziyuan(scoped.row.tag_no)"
            >所属资源</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagina">
      <el-pagination
        :page-size="limit"
        :current-page="page"
        @current-change="handleCurrentChange"
        background
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="所属资源列表"
      :visible.sync="dialogVisible"
      width="900px"
      :before-close="handleClose"
    >
      <div class="serve">
      <div class="serve_box">
        <span>资源名称：</span>
        <el-input
          size="small"
          class="ipt"
          placeholder="请输入内容"
          v-model="resources_name"
          clearable
        >
        </el-input>
        <el-button size="small" type="primary" @click="sousuo_zy">搜索</el-button>
      </div>
    </div>
      <el-table :data="collectionList" stripe style="width: 100%">
        <el-table-column type="index" label="序号"> </el-table-column>
        <el-table-column label="资源名称">
          <template v-slot="scoped">
            <div class="cursor" @click="detail(scoped.row)">
              {{ scoped.row.resources_name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="add_time" label="创建时间"> </el-table-column>
        <el-table-column prop="remarks" label="备注"> </el-table-column>
        <el-table-column prop="address" label="操作" width="100px">
        <template v-slot="scoped">
          <el-button
            size="mini"
            type="primary"
            @click="quxiaoshoucang(scoped.row)"
            >取消收藏</el-button
          >
        </template>
      </el-table-column>
      </el-table>
      <div class="pagina">
        <el-pagination
          :page-size="limit_zy"
          :current-page="page_zy"
          @current-change="handleCurrentChange_zy"
          background
          layout="prev, pager, next"
          :total="total_zy"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tag_name: '',
      resources_name: '',
      limit: 10,
      page: 1,
      indexList: [],
      total: 0,
      // 收藏资源参数 
      dialogVisible: false,
      limit_zy: 10,
      page_zy: 1,
      collectionList: [],
      total_zy: 0,
    }
  },
  mounted() {
    this.index()
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val
      this.index()
    },
    handleCurrentChange_zy(val) {
      this.page_zy = val
      this.collection()
    },
    handleClose() {
      this.dialogVisible = false
    },
    // 收藏关联的标签
    async index() {
      const { data: res } = await this.$http.get(
        'index.php/api/resources/resources/collectiontag',
        {
          params: {
            tag_name: this.tag_name,
            limit: this.limit,
            page: this.page,
          },
        }
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.indexList = res.data.list
      this.total = res.data.total
    },
    // 公共资源收藏列表 /index.php/api/resources/resources/collectionindex
    async collection() {
      const { data: res } = await this.$http.get(
        'index.php/api/resources/resources/collectionindex',
        {
          params: {
            resources_name: this.resources_name,
            tag_no: this.tag_no,
            limit: this.limit_zy,
            page: this.pagez_zy,
          },
        }
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.collectionList = res.data.list
      this.total_zy = res.data.total
    },
    // 搜索关联的标签
    sousuo() {
      this.page = 1
      this.index()
    },
    // 搜索资源
    sousuo_zy() {
      this.pagez_zy = 1
      this.collection()
    },
    ziyuan(tag_no) {
      this.dialogVisible = true
      this.tag_no = tag_no
      this.collection()
    },
    // 取消收藏
    async quxiaoshoucang(row) {
      const { data: res } = await this.$http.post(
        'index.php/api/resources/resources/cancelcollection',
        {
          resources_no: row.resources_no,
        }
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.index()
    },
    // 跳转到资源详情
    detail(item) {
      this.$router.push('/publicDetail?item=' + JSON.stringify(item))
    },
  },
}
</script>

<style lang='less' scoped>
.serve {
  margin-bottom: 30px;
  display: flex;
  .serve_box {
    display: flex;
    align-items: center;
    margin-right: 30px;
    span {
      color: #535454;
      font-size: 14px;
    }
    .ipt {
      width: 220px;
    }
  }
}
.pagina {
  text-align: center;
  padding: 20px;
}
.ipt {
  width: 400px;
}
.cursor {
  cursor: pointer;
}
</style>